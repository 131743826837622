import * as url from "../constant/Endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { objectToQueryString } from "helper/urlquerystring";
import { AES, enc } from "crypto-js";

import "react-toastify/dist/ReactToastify.css"; // Make sure you're importing the CSS file as well
import {
  getAllNotification,
  GetAllFlat,
  FetchAllRoleView as FetchAllRoleViewAgain,
  GetAllMaintenanceType,
  AllDoctorsCategory,
  AllDoctors,
  CountryActiveDeactive,
  AllPrescription,
  GetAllRefundss,
  AllFetchPatinetS,
  CreateDoctorsQualfc,
  FetchAllSlotsStore,
  GetAllSymptoms,
  DoctorsCatgAll,
} from "store/action/index";
import { GetAllAmenities as getAllAmenitiesAgain } from "store/action/index";
import { GetAllFlatSize as getAllFlatSizeAgain } from "store/action/index";
import { GetAllFlat as getAllFlatAgain } from "store/action/index";
import { GetAllTaxes as getAllTaxesAgain } from "store/action/index";
import { dispatch } from "store/index";
const CryptoJS = require("crypto-js");

export default {
  // ========================================= property managment ===================================

  fetchLoginUser: (payload, cb) => {
    if (payload.email === "" || payload.password === "") {
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
      password: payload.password,
    };

    PostData(url.base_url + url.logInApii, data)
      .then((res) => {
        const encryptedToken = CryptoJS.AES.encrypt(
          res.data.data.token,
          url.encryptionSecret
        ).toString();

        const encryptedLoginData = CryptoJS.AES.encrypt(
          JSON.stringify(res?.data?.data),
          url.encryptionSecret
        ).toString();
        localStorage.setItem("token", encryptedToken);
        localStorage.setItem("Id", res.data.data.id);
        localStorage.setItem("isPending", res.data.data.is_pending);

        if (res.data.data.user.user_type == "patient") {
          localStorage.setItem("PatientId", res.data.data.user.patient_id);
        }

        localStorage.setItem("data", encryptedLoginData);
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        if (res?.data?.data?.user_type == "patient") {
          window.location.reload();
          window.location.href = "/appointments/all-appointments";
        } else if (res?.data?.data?.user_type == "doctor") {
          window.location.reload();
          window.location.href = "/appointments/all-appointments";
        } else {
          window.location.reload();
          window.location.href = "/dashboard";
        }
        payload.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        window.location.reload();
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.data?.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  ForgetPassword: (payload, cb) => {
    if (payload.email === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
    };

    PostData(url.base_url + url.ForgetPassword, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload.setLoading(false);
        setTimeout(() => {
          localStorage.setItem("email-forget", payload.email);
          window.location = "/otp-password";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  VerifyOTP: (payload, cb) => {
    if (
      payload.email === "" ||
      payload.password === "" ||
      payload.c_password === ""
    ) {
      payload.setLoading(false);

      return false;
    }
    if (payload.password !== payload.c_password) {
      toast.error("New Password & Confirm Password does not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
      otp: payload.otp,
      password: payload.password,
      c_password: payload.c_password,
    };

    PostData(url.base_url + url.VerifyOTP, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        localStorage.removeItem("email-forget");
        payload.setLoading(false);
        setTimeout(() => {
          if (res.data.data.user_type == "patient") {
            // window.location.href = "https://eclinicasia.com/";
            window.location.href = "/";
          } else {
            window.location.href = "/";
          }
        }, 1000);
        console.log("Forgetttttttttttt", res.data.data.user_type);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchAllApointmentsData: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getallapointmentsapi)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  AllFetchPatinetS: (payload, cb) => {
    getData(
      url.base_url + url.patientgetapi + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        payload?.setCOnditon(false);

        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        payload?.setCOnditon(false);

        cb(err?.response?.status);
      });
  },

  AllDataDashboardFetch: (payload, cb) => {
    getData(
      url.base_url + url.statistics + objectToQueryString(payload?.params)
    )
      .then((res) => {
        console.log("dddddddddddd", payload?.params);
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  AllDataGraph: (payload, cb) => {
    getData(url.base_url + url.graphdash)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  GetPatientsHistory: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.patienthistoryapi)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  PatientsHistoryCr: (payload, cb) => {
    const data = payload?.objj;

    PostRegisterData(url.base_url + url.historypatientcr, data)
      .then((res) => {
        payload?.setLoading(false);
        dispatch(FetchAllSlotsStore());
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchAllSlotsStore: (payload, cb) => {
    getData(url.base_url + url.alSlotsApi)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  GetAllActiveTax: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllActiveTax)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchEditslotStore: (payload, cb) => {
    const data = payload?.payloade;

    PostRegisterData(url.base_url + url.updateslotsssapi, data)
      .then((res) => {
        toast.success("Slots Edited Sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        dispatch(FetchAllSlotsStore());

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchStatusUpdateDepartment: (payload, cb) => {
    const data = payload.customobject;

    PostRegisterData(url.base_url + url.statusupdatedepartmentapi, data)
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        dispatch(DoctorsCatgAll({ setLoading: payload?.setLoading }));
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  EditFetchUpdateDoctorPasswddd: (payload, cb) => {
    const data = payload.payloads;

    PostRegisterData(url.base_url + url.edit_Dcot_pssss, data)
      .then((res) => {
        toast.success("Doctor Updated Password Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  UpdateStatusSymptoms: (payload, cb) => {
    const data = payload.customobject;

    PostRegisterData(url.base_url + url.status_new_symptoms, data)
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        dispatch(GetAllSymptoms());
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchDeletePrescriptionsss: (payload, cb) => {
    const data = payload.customobject;

    PostRegisterData(url.base_url + url.pres_api_delete, data)
      .then((res) => {
        toast.success("Prescription Delete Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        dispatch(AllPrescription());
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  HandleCrudRefundss: (payload, cb) => {
    PostRegisterData(
      url.base_url + url.cancelApirefundd + "/" + payload?.id + "/cancel",
      payload?.remarkssss
    )
      .then((res) => {
        toast.success("Refund Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/appointments/all-appointments";

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(err?.response?.status);
      });
  },

  FetchEditPatient: (payload, cb) => {
    const data = payload.Objj;

    PostRegisterData(url.base_url + url.updatepatientapi, data)
      .then((res) => {
        payload?.setLoading(false);
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          if (localStorage.getItem("PatientId")) {
            window.location.href = "/profile/patient-profile";
          } else {
            window.location.href = "/patient/all-patients";
          }
        }, 500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(err?.response?.status);
      });
  },

  FetchDoctorQualificationDelete: (payload, cb) => {
    const data = payload.customobject;

    PostRegisterData(url.base_url + url.statusupdatequalificationapi, data)
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        dispatch(CreateDoctorsQualfc());
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  REmoveFetchNotifiction: (payload, cb) => {
    PostRegisterData(url.base_url + url.removeNotification + "/" + payload)
      .then((res) => {
        cb(res.data);
        // dispatch(getAllNotification());
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  UpdatePatientHistory: (payload, cb) => {
    const data = payload?.objj;

    PostRegisterData(url.base_url + url.patienthistedit, data)
      .then((res) => {
        payload?.setLoading(false);
        dispatch(FetchAllSlotsStore());
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  UpdateDocotrs: (payload, cb) => {
    const data = payload.customobject;

    PostRegisterData(url.base_url + url.doctorEdit)
      .then((res) => {
        payload?.setLoading(false);

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CategoryAllDoctors: (payload, cb) => {
    getData(
      url.base_url + url.doctoracdc + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  Allrefundget: (payload, cb) => {
    getData(url.base_url + url.refundall + objectToQueryString(payload?.params))
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetPrescriptions: (payload, cb) => {
    getData(url.base_url + url.prescriptions)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // ----------------------------------------------------------------------------
  getAllNotification: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.NotificationGetAll)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  GetTaxes: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      `${url.base_url + url.getAllTaxes}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateTaxes: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createTaxes, payload?.payload)
      .then((res) => {
        toast.success("Tax Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllrefundStatuss: (payload, cb) => {
    PostRegisterData(
      url.base_url + url.statusupdate + "/" + payload?.id + "/approve"
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(GetAllRefundss());
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  BookAppointment: (payload, cb) => {
    PostRegisterData(url.base_url + url.bookappoint, payload?.payloadsss)
      .then((res) => {
        toast.success("Appointment Book Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/appointments/all-appointments";

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(err?.response?.status);
      });
  },

  DateTimeSlot: (payload, cb) => {
    getData(
      url.base_url +
        url.appointslot +
        "/" +
        payload?.objecttt?.doctor_id +
        "/" +
        payload?.objecttt?.date
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  UpdateTaxes: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateTaxes, payload?.payload)
      .then((res) => {
        toast.success("Tax Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateStatusTaxes: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateStatusTaxes +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // <------------- Taxes ------------------->

  // <-------------------- Payment ---------------->

  CreateAddPayment: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createPayment, payload?.payload)
      .then((res) => {
        toast.success("Payment Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        window.location = "/payments/all-payments";

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  createCategory: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.categorycreate, payload?.payloads)
      .then((res) => {
        toast.success("Department Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        window.location = "/doctors/doctors-category";

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // GetAllPayments: (payload, cb) => {
  //   // payload?.setLoading(true);
  //   getData(url.base_url + url.getPayment)
  //     .then((res) => {
  //       // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  GetAllPayments: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.getPayment + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  CreateAddUsers: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        cb(res.data);
        // window.location = "/users/all-users";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  EditUpdateUsers: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/users/all-users";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetAllUsers: (payload, cb) => {
    // payload?.setLoading(true);
    getData(url.base_url + url.getAllUsers)
      .then((res) => {
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  // <----------------- USERS  --------------------->

  //<----------------- NOTIFICATION -------------------->
  AllNotification: (payload, cb) => {
    // payload?.setLoading(true);
    getData(url.base_url + url.getAllNotification)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateFetchRolee: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.registerRollsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  EditRole: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.editRoleAPI, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  AssignPermissionsShop: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.assignpermissionsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Permissions Assigned Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // <------- permissions update localstorage -------------------->
        // let permissionUser = localStorage.getItem("data");

        // let decryptedData = null;
        // if (permissionUser) {
        //   try {
        //     // Code that may throw an exception
        //     decryptedData = AES.decrypt(
        //       permissionUser,
        //       url.encryptionSecret
        //     ).toString(enc.Utf8);
        //     // ...
        //   } catch (error) {
        //     localStorage.clear();
        //     window.location.reload();
        //   }
        // }
        // let permissionsArray = [];

        // try {
        //   permissionsArray = decryptedData && JSON.parse(decryptedData);
        // } catch (e) {}

        // console.log("sd: " ,res,payload,permissionsArray)
        // <------- permissions update localstorage -------------------->

        window.location.href = "/";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  getAllPermissions: (payload, cb) => {
    getData(url.base_url + url.allpermissionsApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  GetRolePermissions: (payload, cb) => {
    getData(url.base_url + url.getRolePermissionsApi + `/${payload?.roleId}`)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  HandleEditUserApi: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.edituserapis, data)
      .then((res) => {
        cb(res.data);
        toast.success("User Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchRegisterBrandUserrs: (payload, cb) => {
    PostRegisterData(url.base_url + url.registerbranduser, payload)
      .then((res) => {
        cb(res.data);
        if (res?.data.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.success("User Registered Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/user-management/users";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchAllUsersView: (payload, cb) => {
    getData(url.base_url + url.allUsersApi + "?page=" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchUsersStatusUpdateView: (payload, cb) => {
    getData(url.base_url + url.usersStatusUpdateApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  ChangePassword: (payload, cb) => {
    const data = {
      current_password: payload.current_password,
      password: payload.password,
    };

    PostRegisterData(url.base_url + url.ChangePassword, data)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
        toast.success("Password Change Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload.setModalOpen(false);

        window.location.href = "/dashboard";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          payload.setLoading(false);
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        payload.setLoading(false);
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchAllRoleView: (payload, cb) => {
    getData(url.base_url + url.allRoleApi)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
      });
  },

  GetAllCounts: (payload, cb) => {
    // console.log(payload);
    let apiUrl = url.base_url + url.getAllCounts;

    if (payload?.company_id) {
      const queryParams = { company_id: payload?.company_id };
      apiUrl += `${objectToQueryString(queryParams)}`;
    }
    // console.log(apiUrl);

    getData(apiUrl)
      .then((res) => {
        cb(res?.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload?.setLoading(false);
      });
  },

  // --------------------------------------------------------E-CLINIC-----------------------------------

  CountryAll: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getCountry)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CountryAcDc: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.acCountrydc + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetReportss: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.allreports + objectToQueryString(payload?.paramss)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  DoctorsAll: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.alldoctors + objectToQueryString(payload?.paramss)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  DoctorsDelete: (payload, cb) => {
    const data = payload.customobject;

    PostRegisterData(url.base_url + url.deletedocs, data)
      .then((res) => {
        payload?.setLoading(false);
        dispatch(AllDoctors());
        toast.success("Status Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  AppointmentEdit: (payload, cb) => {
    const data = payload?.payloads;

    PostRegisterData(url.base_url + url.appointeditt, data)
      .then((res) => {
        payload?.setLoading(false);
        dispatch(AllDoctors());
        toast.success("Comment Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  PrescriptionPost: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.crprescription, data)
      .then((res) => {
        payload?.setLoading(false);
        dispatch(AllDoctors());
        toast.success("Prescriptions Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  UpdatePrescriptions: (payload, cb) => {
    const data = payload?.payloads;

    PostRegisterData(url.base_url + url.editprescription, data)
      .then((res) => {
        payload?.setLoading(false);
        dispatch(AllPrescription());
        toast.success("Prescriptions Edit Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  CountryCreate: (payload, cb) => {
    const data = payload?.payloads;

    PostRegisterData(url.base_url + url.createCountry, data)
      .then((res) => {
        toast.success("Country Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // payload?.setLoading(false);
        dispatch(CountryActiveDeactive());
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        // payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  UpdateCountry: (payload, cb) => {
    const data = payload?.payloads;

    PostRegisterData(url.base_url + url.updateCountry, data)
      .then((res) => {
        toast.success("Country Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // payload?.setLoading(false);
        dispatch(CountryActiveDeactive());
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        // payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  DetailsPatients: (payload, cb) => {
    const data = payload?.objj;

    PostRegisterData(url.base_url + url.patientsdtls, data)
      .then((res) => {
        payload?.setLoading(false);
        // dispatch(CountryActiveDeactive());
        cb(res.data);
        toast.success("Profile Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  DoctorsCategory: (payload, cb) => {
    getData(url.base_url + url.doctorscategory)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllReportsAppoint: (payload, cb) => {
    getData(
      url.base_url +
        url.reportsAppointment +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllPatientReport: (payload, cb) => {
    getData(
      url.base_url +
        url.reportPatientWise +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  DoctorsCreate: (payload, cb) => {
    const data = payload?.payloads;

    PostRegisterData(url.base_url + url.createdoctors, data)
      .then((res) => {
        cb(res.data);
        toast.success("Doctor Register Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        setTimeout(() => {
          window.location.href = "/doctors/all-doctors";
        }, 500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message == "Validation Error.") {
          const errorMessages = err?.response?.data?.data;
          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  DoctorsEdit: (payload, cb) => {
    const data = payload?.payloads;

    PostRegisterData(url.base_url + url.editdoctors, data)
      .then((res) => {
        cb(res.data);
        toast.success("Doctor Edit Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        setTimeout(() => {
          window.location.href = "/doctors/all-doctors";
        }, 500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        if (err?.response?.data?.message == "Validation Error.") {
          const errorMessages = err?.response?.data?.data;
          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  DoctorsCategoryCr: (payload, cb) => {
    const data = payload.payloads;

    PostRegisterData(url.base_url + url.catgedoctors, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        dispatch(AllDoctorsCategory());
        toast.success("Doctor Department Create Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        setTimeout(() => {
          window.location.href = "/doctors/doctors-category";
        }, 500);
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        if (err?.response?.data?.message == "Validation Error.") {
          Object.values(err?.response?.data?.data).map((v) =>
            toast.error(v[0], {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              bodyClassName: "toastStyle",
            })
          );
        }
      });
  },

  DoctorsCategoryEdit: (payload, cb) => {
    PostRegisterData(url.base_url + url.catgeedit, payload?.payloads)
      .then((res) => {
        cb(res.data);
        toast.success("Doctor Department Edit Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        setTimeout(() => {
          window.location.href = "/doctors/doctors-category";
        }, 500);
        dispatch(AllDoctorsCategory());
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        } else if (err?.response?.data?.error) {
          Object.values(err?.response?.data?.error).map((v) =>
            toast.error(v[0], {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              bodyClassName: "toastStyle",
            })
          );
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  DoctorsQulification: (payload, cb) => {
    getData(
      url.base_url + url.quafctdoctors + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  AllSymptoms: (payload, cb) => {
    getData(
      url.base_url + url.all_symptoms + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetTemplate: (payload, cb) => {
    getData(url.base_url + url.alltemplate)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  DrExportReport: (payload, cb) => {
    getData(
      url.base_url + url.reportexport + objectToQueryString(payload?.paramss)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  PtExportReport: (payload, cb) => {
    getData(
      url.base_url + url.reportexportpt + objectToQueryString(payload?.paramss)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllReportExports: (payload, cb) => {
    getData(
      url.base_url +
        url.reportexporttttt +
        objectToQueryString(payload?.paramsoo)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateAddSymptom: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.create_symptom, data)
      .then((res) => {
        toast.success("Symptoms Added Sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        payload?.setLoading(false);
        payload?.closeModal();

        dispatch(GetAllSymptoms());
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.openModal();
      });
  },

  AllCountryDelete: (payload, cb) => {
    const data = payload?.customobject;

    PostRegisterData(url.base_url + url.countrydel, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        dispatch(CountryActiveDeactive());
        toast.success("Status Update Sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  PatientsDelete: (payload, cb) => {
    const data = payload?.customobject;

    PostRegisterData(url.base_url + url.allPatientsdel, data)
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        payload?.setLoading(false);
        dispatch(AllFetchPatinetS());
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CreateQulification: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.quafccreate, data)
      .then((res) => {
        cb(res.data);
        toast.success("Doctor Qualification Added Sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        dispatch(CreateDoctorsQualfc());
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EditTemplate: (payload, cb) => {
    const data = payload.payloads;

    PostRegisterData(url.base_url + url.editttemp, data)
      .then((res) => {
        cb(res.data);
        toast.success("Template Updated Sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        window.location.href = "/templates/all-templates";
        // dispatch(CreateDoctorsQualfc());
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EditUpdateSymptom: (payload, cb) => {
    PostRegisterData(
      url.base_url + url.edit_symptom + objectToQueryString(payload?.params)
    )
      .then((res) => {
        toast.success("Symptoms Edited Sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        dispatch(GetAllSymptoms());
        payload?.setLoading(false);
        payload?.closeModal();
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.openModal();
      });
  },

  RatingAdd: (payload, cb) => {
    let data = payload?.payloads;
    PostRegisterData(url.base_url + url.ratings, data)
      .then((res) => {
        toast.success("Rating Recevied Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        // dispatch(GetAllSymptoms());
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EditQulification: (payload, cb) => {
    PostRegisterData(
      url.base_url + url.quafcedit + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        toast.success("Doctor Qualifiaction Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(CreateDoctorsQualfc());
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  DoctorsDetails: (payload, cb) => {
    getData(url.base_url + url.doctorsdetails + payload.id)
      .then((res) => {
        // payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        // payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },
};

function PostData(URL, data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(URL, data, config);
}
export function JsonPostData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getNewTokenn}`,
    },
  };
  return axios.post(URL, data, config);
}
function PostRegisterData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${NewToekn}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };
  return axios.post(URL, data, config);
}

// axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
function getData(URL) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.get(URL, config);
}
