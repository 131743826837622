export const temp_url = "https://eclinic.alisonstech-dev.com/Admin/public/api/";
export const base_url = "https://eclinic.alisonstech-dev.com/Admin/public/api/";
export const PathUrl = "https://eclinic.alisonstech-dev.com/Admin/public";

// export const temp_url = "http://127.0.0.1:8000/api/";
// export const base_url = "http://127.0.0.1:8000/api/";
// export const PathUrl = "http://127.0.0.1:8000";

export const encryptionSecret = "2d84n0OiymE6eQtvCsdfdstett5zQmm2OsP";

export const logInApii = "login";
export const ForgetPassword = "forgot_password/sendCode";
export const ChangePassword = "password/changePassword";
export const VerifyOTP = "forgot_password/verifyCode";
export const registerRollsApi = "Role/create-role";
export const editRoleAPI = "Role/Update";
export const registerbranduser = "User/register";
export const allUsersApi = "User/get";
export const usersStatusUpdateApi = "User/update";
export const edituserapis = "User/update";
export const alSlotsApi = "domainparmeter/slot/get";
export const getallapointmentsapi = "appointments/get";
export const patientgetapi = "patient/get";
export const patienthistoryapi = "patient/history/get";
export const historypatientcr = "patient/history/store";
export const NotificationGetAll = "Notifications/get";
export const removeNotification = "Notifications/read";
export const getAllTaxes = "domainparmeter/tax/get";
export const createTaxes = "domainparmeter/tax/create";
export const updateTaxes = "domainparmeter/tax/update";
export const updateStatusTaxes = "domainparmeter/tax/";
export const getAllActiveTax = "domainparmeter/tax/get/active";
export const createPayment = "Payment/create";
export const getPayment = "Payment/get";
export const createUsers = "user_management/create";
export const updateUsers = "user_management/update";
export const getAllUsers = "user_management/get";
export const getAllNotification = "domainparmeter/get/notifications";
export const allRoleApi = "Role/get-roles";
export const assignpermissionsApi = "Permission/assign-permission";
export const getRolePermissionsApi = "Permission/get-rolepermissions";
export const allpermissionsApi = "Permission/get-allpermissions";
export const getAllCounts = "Dashboard/get/count";
export const alldoctors = "Doctor/get/all";
export const deletedocs = "Doctor/delete";
export const createdoctors = "Doctor/create";
export const editdoctors = "Doctor/update";

export const appointeditt = "appointments/update/comment";

export const doctorscategory = "domainparmeter/categories/get";

export const categorycreate = "domainparmeter/categories/create";
export const catgedoctors = "domainparmeter/categories/create";
export const catgeedit = "domainparmeter/categories/update";
export const quafctdoctors = "domainparmeter/qualifications/qualifications";
export const all_symptoms = "Symptoms/getAll";
export const status_new_symptoms = "Symptoms/delete";
export const pres_api_delete = "prescription/delete";
export const edit_Dcot_pssss = "Doctor/password/update";

export const quafcedit = "domainparmeter/qualifications/updatequalifications";
export const edit_symptom = "Symptoms/update";
export const quafccreate = "domainparmeter/qualifications/addqualifications";
export const create_symptom = "Symptoms/create";
export const doctorsdetails = "Doctor/get/all?id=";
export const updateslotsssapi = "domainparmeter/slot/update";
export const getCountry = "domainparmeter/country/get";
export const acCountrydc = "domainparmeter/country/getAll";
export const createCountry = "domainparmeter/country/create";
export const patientsdtls = "patient/store/detail";
export const statusupdatedepartmentapi = "domainparmeter/categories/delete";
export const statusupdatequalificationapi =
  "domainparmeter/qualifications/deletequalifications";

export const statistics = "reports/statistics";

export const graphdash = "reports/graphData";

export const updatepatientapi = "patient/update";
export const patienthistedit = "patient/history/update";
export const doctorEdit = "Doctor/update";
export const doctoracdc = "domainparmeter/categories/get/all";
export const reportsAppointment = "reports/appointmontly";
export const reportPatientWise = "reports/getPatientWise";
export const updateCountry = "domainparmeter/country/update";
export const countrydel = "domainparmeter/country/delete";
export const allPatientsdel = "patient/delete";
export const prescriptions = "prescription/get";
export const crprescription = "prescription/create";
export const editprescription = "prescription/update";
export const alltemplate = "domainparmeter/template/get";
export const editttemp = "domainparmeter/template/update";
export const ratings = "rating-doctors/add";
export const allreports = "reports/appointment";

export const bookappoint = "appointments/book";

export const appointslot = "appointments/check";

export const reportexport = "reports/appointmontly";
export const reportexportpt = "reports/getPatientWise";
export const reportexporttttt = "reports/appointment";

export const cancelApirefundd = "appointments";
export const refundall = "appointments/get/cancel";
export const statusupdate = "appointments/cancellations";
